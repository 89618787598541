.btn-grad {
  background-image: linear-gradient(
    to right,
    #f3a800,
    #ff7e5f 50%
  ); /* linear-gradient(to right, #ff6e7f 0%, #bfe9ff  51%, #ff6e7f  100%);   */
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #000;
  /* hover */

  border-radius: 30px;
  display: block;
  font-size: 15px;
  /* font-family: 'HandWriting'; */

  &:hover {
    box-shadow: 0 0 5px #eee;
  }
}

.btn-grad:hover {
  /* color: #555452; */
  text-decoration: none;
}
