/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* 
.MathJax_Display,
.MJXc-display,
.MathJax_SVG_Display {
  overflow-x: auto;
  overflow-y: hidden;
} */

/* math {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  row-gap: 10px;
} */

/* .mathjax-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap; 
} */

/* CSS for safe area padding */
/* env(safe-area-inset-bottom); */
/* body,
#root {
  margin-bottom: 50px;
} */

table {
  border-collapse: collapse; /* Ensures no double borders */
  width: 100%;
}

p {
  margin-top: 6px;
  margin-bottom: 6px;
}

table,
th,
td {
  /* border: 1px solid black; */
  padding: 8px; /* Optional padding inside cells */
}
